import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, Grid, Typography } from '@material-ui/core';

import { actions as paymentActions } from '@pro/web-common/core/payment/actions';
import { getFormState, getPaymentIntentKey } from '@pro/web-common/core/payment/selectors';

import PageTitle from '@pro/web-common/components/page-title';
import OfferForm from '@pro/web-common/components/offer-form';
import AddPaymentMethodModal from '@pro/web-common/components/add-payment-method-modal';

import { DEFAULT_CURRENCY_ID } from 'constants/currency';

import { PRODUCTS_IDS } from 'constants/products';


const Offer = React.memo(({ formState: { error, fetching }, payForSetup, createPaymentIntent, paymentIntentKey }) => {
  const [userData, setUserData] = useState(null);
  const [isAddPaymentModalVisible, setIsAddPaymentModalVisible] = useState(false);
  const [currencyId, setCurrencyId] = useState(DEFAULT_CURRENCY_ID);

  const onOfferSubmit = useCallback(({ data }) => {
    setUserData(data);
    setIsAddPaymentModalVisible(true);
  }, []);

  const handlePaymentAdd = useCallback(({ source: { id } }) => {
    payForSetup({
      id,
      ...userData,
    });
    setIsAddPaymentModalVisible(false);
  }, [userData, currencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreatePaymentIntent = useCallback(() => createPaymentIntent({ productId: PRODUCTS_IDS.SETUP, currency: currencyId }), [currencyId]);

  return (
    <Container maxWidth="md">
      <PageTitle
        title="We'll set everything up for you!"
        subtitle={<>If you are a little short on time, we'll set up your app for you. We just need a couple of things.</>}
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Typography>
            Use this form to confirm your details, attach a PDF and pay the one off service fee - we'll get everything sorted.<br /><br />
            We will ask any questions, set up training and sort everything you need. You&apos;ll be good to go in no time!<br />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <OfferForm
            fetching={fetching}
            isError={!!error}
            onSubmit={onOfferSubmit}
            currencyId={currencyId}
            onCurrencyIdUpdate={setCurrencyId}
          />
        </Grid>

        {isAddPaymentModalVisible && (
          <AddPaymentMethodModal
            onClose={() => setIsAddPaymentModalVisible(false)}
            isSubmitting={fetching}
            isError={!!error}
            onAdd={handlePaymentAdd}
            createPaymentIntent={handleCreatePaymentIntent}
            billingDetails={userData}
            paymentIntentKey={paymentIntentKey}
            isPaymentIntentsMode
          />
        )}
      </Grid>
    </Container>
  );
});

Offer.propTypes = {
  formState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  payForSetup: PropTypes.func.isRequired,
  createPaymentIntent: PropTypes.func.isRequired,
  paymentIntentKey: PropTypes.string,
};

Offer.defaultProps = {
  paymentIntentKey: null,
};


const mapStateToProps = (state) => ({
  formState: getFormState(state),
  paymentIntentKey: getPaymentIntentKey(PRODUCTS_IDS.SETUP)(state),
});

const mapDispatchToProps = {
  createPaymentIntent: paymentActions.createPaymentIntent,
  payForSetup: paymentActions.payForSetup,
};


export default connect(mapStateToProps, mapDispatchToProps)(Offer);
