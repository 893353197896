import { createMuiTheme } from '@material-ui/core/styles';

import defaultTheme from '@pro/web-common/theme';


const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#e3e3e3',
      main: '#111110',
      additional: '#686869',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Ubuntu',
  },
});

export const LOGO_WIDTH = '160px';

export default theme;
