import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Link,
} from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';

import SectionImage from 'assets/images/home/hero1.jpeg';

import { styles } from './styles';


const StartNowSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      bgcolor="primary.light"
      pt={10}
      pb={10}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h3"
            >
              Start now.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h1"
              >
                Create a table service digital menu, messaging &amp; upsell program for your restaurant, cafe or bar.
              </Typography>
            </Box>

            <Typography>
            Can you change the menu, even DURING service to upsell/promote individual menu items?<br />
              Can you easily contact recent diners with brilliant added value content, vouchers, messaging &amp; more?<br />
              Can you encourage re booking and sharing, highlighting optimal times?<br />
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                F&amp;B is your digital menu app, with added features to support your upsell program.
              </Box>
            </Typography>

            <Box
              mt={3}
              mb={[4, 0]}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                to="#pricing"
                smooth
                offset={-64}
                component={NavHashLink}
              >
                PRICING
              </Button>

              <Typography component="span">
                Limited FREE version, upgrade when ready!
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

            <Box mt={4}>
              {STORE_LINKS.map(({ id, href, icon }) => (
                <Link
                  key={id}
                  href={href}
                  className={classes.storeLink}
                >
                  <img
                    src={icon}
                    alt={id}
                  />
                </Link>
              ))}
            </Box>
            <Box
              mt={1}
              display="block"
              component="span"
              fontWeight="fontWeightBold"
            >
              Commission free - customers pay you, not us!
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


StartNowSection.propTypes = {};

StartNowSection.defaultProps = {};


export default StartNowSection;
