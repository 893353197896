import React from 'react';

import {
  Container,
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';

import Message from 'assets/images/app-screenshots/message.png';
import ClientHome from 'assets/images/app-screenshots/client_home.png';
import Menu from 'assets/images/app-screenshots/menu.png';
import Voucher from 'assets/images/app-screenshots/voucher.png';


const ScreenshotsSection = React.memo(() => (
  <Box
    bgcolor="primary.additional"
    color="white"
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={12}
          sm={3}
        >
          <img
            src={Message}
            alt="Upsell Push Message Restaurant Cafe Bar"
          />
        </Grid>

        <Hidden only="xs">
          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={ClientHome}
              alt="Digital table service menu app"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={Menu}
              alt="Upsell Menu App QR code"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={Voucher}
              alt="Voucher for upselling FnB restaurant bar cafe"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  </Box>
));


ScreenshotsSection.propTypes = {};

ScreenshotsSection.defaultProps = {};


export default ScreenshotsSection;
