import messageFakeImage1 from 'assets/images/coupleCake1.png';
import messageFakeImage2 from 'assets/images/coupleCoffee1.png';


export const DEFAULT_MESSAGES = [
  {
    id: 1,
    title: 'Here is a sample headline of a message',
    image: messageFakeImage1,
    date: 'Date: 12 December 2020',
  },
  {
    id: 2,
    title: 'Here is another sample message',
    image: messageFakeImage2,
    date: 'Date: 12 December 2020',
  },
];

export const DEFAULT_TAGS = [
  {
    id: 'all',
    title: 'All',
  },
  {
    id: 'updates',
    title: 'Updates',
  },
  {
    id: 'offers',
    title: 'Offers',
  },
];
