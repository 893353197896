import { createSelector } from 'reselect';


const getLocalState = (state) => state.payment;

export const getFormState = createSelector(
  getLocalState,
  ({ form }) => form,
);

export const getPaymentIntentKey = (productId) => createSelector(
  getLocalState,
  ({ paymentIntentKeys }) => paymentIntentKeys[productId] || null
);
