import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { styles } from './styles';


const Section = React.memo(({ title, backgroundColor, html, image, typeface }) => {
  const classes = styles({ backgroundColor, typeface });

  return (
    <Box className={classes.container}>
      {image && (
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={image.filePath}
            alt="section-hero"
          />
        </div>
      )}

      {title && (
        <Box className={classes.title}>
          {title}
        </Box>
      )}

      <Box className={classes.html}>
        {html && (
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
          />
        )}
      </Box>
    </Box>
  );
});


Section.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.shape({}),
  html: PropTypes.string,
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  typeface: PropTypes.string,
};

Section.defaultProps = {
  title: null,
  backgroundColor: null,
  html: null,
  image: null,
  typeface: null,
};


export default Section;
