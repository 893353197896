import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getBase64Strings } from 'exif-rotate-js/lib';

import { Box, Button, Typography, IconButton } from '@material-ui/core';

import {
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { convertBase64toFile, isPng } from '@pro/web-common/utils/image';
import ImageWithPlaceholder from '@pro/web-common/components/image-with-placeholder';
import ConditionalTooltip from '@pro/web-common/components/conditional-tooltip';

import { isTestVersion } from 'constants/product-config';

import { TYPES } from './config';
import { styles } from './styles';


const FileInput = React.memo(({ id, fileName, onChange, onBlur, formats, error, submitLabel, type, disabled, onDelete, ...placeholderProps }) => {
  const classes = styles({ error });

  const onFileChange = useCallback(async ({ target: { files } }) => {
    if (files && files.length && files[0]) {
      const isPngFile = isPng(files[0]);

      if (type === TYPES.IMAGE && !isPngFile) {
        const data = await getBase64Strings(files);
        onChange(convertBase64toFile(data, files[0].name));
      } else {
        onChange(files[0]);
      }
    }
  }, [onChange, type]);

  const handleDelete = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onDelete();
  }, [onDelete]);

  return (
    <>
      <input
        disabled={disabled}
        accept={formats}
        className={classes.hiddenInput}
        id={id}
        multiple
        type="file"
        onChange={onFileChange}
        onBlur={onBlur}
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id}>
        {type === TYPES.INPUT && (
          <Box className={classes.container}>
            <Typography
              noWrap
              className={classes.fileInputText}
            >
              {fileName}
            </Typography>

            <Box>
              <ConditionalTooltip
                title={`${isTestVersion ? 'Upgrade' : 'Subscribe'} to add logo`}
                isShown={disabled}
              >
                <Button
                  fullWidth
                  size="small"
                  color="primary"
                  variant="contained"
                  component="span"
                  classes={{
                    root: classes.fileInputButton,
                    label: classes.fileInputButtonLabel,
                  }}
                  disabled={disabled}
                >
                  {submitLabel}
                </Button>
              </ConditionalTooltip>
            </Box>

            {
              onDelete && fileName && (
                <Box>
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </Box>
              )
            }
          </Box>
        )}
        {type === TYPES.IMAGE && (
          <ImageWithPlaceholder
            {...placeholderProps}
            error={error}
          />
        )}
      </label>
    </>
  );
});

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.bool,
  submitLabel: PropTypes.string,
  type: PropTypes.oneOf([TYPES.INPUT, TYPES.IMAGE]),
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
};

FileInput.defaultProps = {
  fileName: '',
  error: false,
  onBlur: null,
  submitLabel: 'Upload',
  type: 'input',
  disabled: false,
  onDelete: null,
};


export default FileInput;
