import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import Easy1 from 'assets/images/123/Brand_Your_Guest_App.png';
import Easy2 from 'assets/images/123/Add_Your_Hotel_Resort_Attraction_Tour.png';
import Easy3 from 'assets/images/123/Tell_Your_Guests_QR_Code.png';
import Easy4 from 'assets/images/123/App_Different_Kettle_Of_Fish.png';

import CaseItem from './case-item';


const CasesSection = React.memo(() => (
  <Box
    bgcolor="primary.light"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Easy as 1, 2, 3
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Once you have set up your digital menu app, design and create a regular upsell program. Highlight more profitable menu items, toggle visibility to suit your strategy, create messaging Calls to Action to complete the circle.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy1}
            title="Step 1"
            subtitle="Make your digital menu &apos;you&apos;"
            alt="Create digital QR menu app"
            description={(
              <>
                Create your menus using a familiar admin/CMS, PLUS include optional online booking &amp; ordering, messaging, vouchers &amp; more in your digital menu app.
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy2}
            title="Step 2"
            subtitle="Design your upsell program day to day"
            alt="Upsell restaurant, bar and cafe digital menu"
            description={(
              <>
                Increase profits by creating an upsell program. Optimise menu item visibility &amp; pricing DURING service. Add scheduled &amp; dynamic messaging to encourage uptake and re booking.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy3}
            title="Step 3"
            subtitle="Deliver unrivalled F&amp;B experience AND maximise upsell opportunity"
            alt="F&amp;B customers just need a QR code"
            description={(
              <>
                Provide everything your customers need in one place. At the same time open a brand new uncluttered channel to maximise re booking and upsell opportunity.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy4}
            title="A completely different kettle of fish"
            subtitle="F&amp;B is different from normal upsell tools"
            alt="No commission, unrivalled messaging and support"
            description={(
              <>
                <strong>Customers pay YOU in the normal way.</strong> We do not charge commission -  we enable you to maximise return on your brilliant F&amp;B experience.
              </>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


CasesSection.propTypes = {};

CasesSection.defaultProps = {};


export default CasesSection;
