import BusCard from 'assets/images/artworks/BusCard.png';
import A4 from 'assets/images/artworks/A4Portrait.png';
import A4L from 'assets/images/artworks/A4Labels24.png';


export const ARTWORKS = [
  {
    id: 1,
    artwork: BusCard,
    dimensions: {
      width: 240,
      height: 155,
    },
    qrPositions: [
      {
        x: 14,
        y: 44,
        width: 98,
        height: 98,
      },
    ],
  },
  {
    id: 2,
    artwork: A4,
    dimensions: {
      width: 595,
      height: 842,
    },
    qrPositions: [
      {
        x: 147.5,
        y: 271,
        width: 300,
        height: 300,
      },
    ],
  },
  {
    id: 3,
    artwork: A4L,
    dimensions: {
      width: 595,
      height: 842,
    },
    qrPositions: [
      {
        x: 23,
        y: 48,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 144,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 240,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 336,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 432,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 528,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 624,
        width: 76,
        height: 76,
      },
      {
        x: 23,
        y: 720,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 48,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 144,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 240,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 336,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 432,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 528,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 624,
        width: 76,
        height: 76,
      },
      {
        x: 215,
        y: 720,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 48,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 144,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 240,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 336,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 432,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 528,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 624,
        width: 76,
        height: 76,
      },
      {
        x: 404,
        y: 720,
        width: 76,
        height: 76,
      },
    ],
  },
];
