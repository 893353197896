import { makeStyles } from '@material-ui/core/styles';
import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: ({ typeface, coverImage, imprintBackgroundColor }) => ({
    padding: theme.spacing(1),
    fontFamily: typeface,
    ...(coverImage ? { backgroundImage: `url('${coverImage.url}')` } : {}),
    backgroundColor: parseRgbaColor(imprintBackgroundColor),
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '50px',
    margin: theme.spacing(-1, -2, 0, -2),
  },
  logo: {
    maxHeight: '50px',
    display: 'block',
  },
  issueName: ({ typeface }) => ({
    textTransform: 'uppercase',
    textAlign: 'right',
    margin: theme.spacing(0.3, 0, 0, 0),
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: typeface,
  }),
  htmlContainer: ({ coverTitlesOpacity }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    opacity: coverTitlesOpacity / 100,
  }),
}));
