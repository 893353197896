import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box, Typography,
} from '@material-ui/core';

import { withFormik } from 'formik';

import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import FileInput from '@pro/web-common/components/file-input';
import CurrencySelector from '@pro/web-common/components/currency-selector';

import { OFFER_TYPE_PRICES } from 'constants/subscriptions-config';
import { CURRENCY_LIST } from 'constants/currency';

import { formValidationSchema, fileFormats } from './config';
import { styles } from './styles';


const OfferForm = React.memo(({ currencyId, onCurrencyIdUpdate, fetching, isError, onSubmit, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formikProps;

  const onFileChange = useCallback((file) => {
    setFieldValue('file', file);
    setFieldValue('fileName', file.name);
  }, []);

  useEffect(() => {
    if (!fetching && !isError) {
      resetForm();
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner isSubmitting={fetching}>
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Name"
                  placeholder="Your Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="website"
                  label="Website Address"
                  placeholder="Website Address"
                  name="website"
                  value={values.website}
                  autoComplete="new-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.website && touched.website}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FileInput
                  id="fileUploader"
                  fileName={values.fileName}
                  onChange={(file) => onFileChange(file)}
                  onBlur={handleBlur}
                  error={(errors.file || errors.fileName) && touched.file}
                  formats={fileFormats}
                  submitLabel="Upload File"
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Box
                  textAlign="right"
                  mt={4}
                >
                  <CurrencySelector
                    currencyId={currencyId}
                    onChange={onCurrencyIdUpdate}
                  />
                </Box>

                <Box
                  textAlign="right"
                  mt={2}
                >
                  {currencyId && (
                    <>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={fetching}
                      >
                        Pay {CURRENCY_LIST[currencyId].sign}{OFFER_TYPE_PRICES[currencyId].price}
                      </Button>

                      {CURRENCY_LIST[currencyId].vat && (
                        <Box mt={1}>
                          <Typography variant="caption">
                            Plus VAT {CURRENCY_LIST[currencyId].vat}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

OfferForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  currencyId: PropTypes.string.isRequired,
  onCurrencyIdUpdate: PropTypes.func.isRequired,
};

OfferForm.defaultProps = {
  isError: false,
};


export default withFormik({
  mapPropsToValues: () => {
    const values = {
      name: '',
      email: '',
      website: '',
      file: null,
    };

    return values;
  },
  handleSubmit: (data, { props: { onSubmit } }) => onSubmit({ data }),
  validationSchema: () => formValidationSchema(),
})(OfferForm);
