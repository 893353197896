const config = {
  firebase: {
    apiKey: 'AIzaSyC2DGJEdP-2KYQPGM4FAiGjytFDA0TPA34',
    authDomain: 'foodbeverage-b0d50.firebaseapp.com',
    databaseURL: 'https://foodbeverage-b0d50.firebaseio.com',
    projectId: 'foodbeverage-b0d50',
    storageBucket: 'foodbeverage-b0d50.appspot.com',
    messagingSenderId: '403438312506',
    appId: '1:403438312506:web:83894086ec291c4aec2903',
    measurementId: 'G-SXVHS3PK46',
  },
  stripePublicAPIKey: 'pk_live_51HYqfPL9W1UgqFKKurocH5J2pXQqUGC7kpbhKy9vpTWMygONAs69hwnLxoIFZwTX4T3rhRWASXIShHa2MuW7hGet008YWreUpC',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCNOSfVlUS22XfRFXCfk1WYsxPs3ccTFSk&libraries=places,geometry',
  },
};


export default config;
