import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Box } from '@material-ui/core';

import { actions as supportActions } from '@pro/web-common/core/support/actions';
import { getSendingState } from '@pro/web-common/core/support/selectors';
import { getIsSignIn } from '@pro/web-common/core/user/selectors';

import meta from 'content/meta';

import StartNowSection from './start-now-section';
import BuildManualSection from './build-manual-section';
import ScreenshotsSection from './screenshots-section';
import CasesSection from './cases-section';
import PricingSection from './pricing-section';
import PromoSection from './promo-section';
import ContactUsSection from './contact-us-section';


const Home = React.memo(({ sendMessage, sendingState: { error, fetching }, isSignIn }) => (
  <>
    {meta.home}

    <Box
      overflow="hidden"
    >
      <StartNowSection />
      <BuildManualSection />
      <ScreenshotsSection />
      <CasesSection />
      <PricingSection isSignIn={isSignIn} />
      <PromoSection />
      <ContactUsSection
        onSubmit={sendMessage}
        isSubmitting={fetching}
        isError={!!error}
      />
    </Box>
  </>
));


Home.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  sendingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  isSignIn: PropTypes.bool.isRequired,
};

Home.defaultProps = {};

const mapStateToProps = (state) => ({
  sendingState: getSendingState(state),
  isSignIn: getIsSignIn(state),
});

const mapDispatchToProps = {
  sendMessage: supportActions.sendMessage,
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);
