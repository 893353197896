import React from 'react';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import SectionImage from 'assets/images/home/build-manual-app-hero.png';

import { styles } from './styles';


const BuildManualSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      pt={6}
      pb={4}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              QR code digital menu
            </Typography>

            <Box
              fontSize="body1.fontSize"
              className={classes.list}
            >
              <ul>
                <li>Brand with your logo, colours, fonts &amp; images</li>
                <li>Add digital table service menus</li>
                <li>No PDF artwork or coding required</li>
                <li><strong>Commission free</strong> - customers pay you, not us</li>
                <li>Include ANY online ordering &amp; table booking links</li>

              </ul>
            </Box>

            <Typography>
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                Diners just scan a QR, opens at your digital menu app!
              </Box>
            </Typography>
            <Typography
              gutterBottom
              variant="h4"
            >
              <br />Upsell Program
            </Typography>

            <Box
              className={classes.list}
              fontSize="body1.fontSize"
            >
              <ul>
                <li>Re order menu instantly to increase priority</li>
                <li>Update pricing and toggle item visibility</li>
                <li>Message vouchers &amp; added value content</li>
                <li>Schedule regular messaging Call to Actions</li>
                <li>Send Push Notifications, on or off property</li>
                <li><strong>Mobile compatible CMS/Admin</strong></li>
                <li>Unrivalled support 24/7</li>
              </ul>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

          </Grid>

        </Grid>
      </Container>
    </Box>
  );
});


BuildManualSection.propTypes = {};

BuildManualSection.defaultProps = {};


export default BuildManualSection;
