// import ManualEditImage from 'assets/images/support/manual_edit_image.jpg';
import ManualEditBold from 'assets/images/support/manual_edit_bold.jpg';
import AddSubs from 'assets/images/support/add_subs.jpg';
import Duplicate from 'assets/images/support/duplicate.jpg';
import PageTypes from 'assets/images/support/pagetypes.jpg';
import GuestAnimated from 'assets/images/support/guestInfoAnimated.gif';
import HomePageImage from 'assets/images/support/addingImagesHomeAnimation.gif';
import AddPhoneNumber from 'assets/images/support/addingPhoneAnimated.gif';
import AddRichText from 'assets/images/support/addingRichTextAnimation.gif';
import AddWebsite from 'assets/images/support/addingWebsiteAnimated.gif';
import AddMessage from 'assets/images/support/SendingImageMessage14Sept2020.gif';
import QuickMessage from 'assets/images/support/QuickMessage14Sept2020.gif';
import ConfigureBrand from 'assets/images/support/ConfigureBranding14Sept2020.gif';
import EditMenu from 'assets/images/support/EditMenuColorTrack14Sept2020.gif';
import TrackTrace from 'assets/images/support/TrackandTraceMessage14Sept2020.gif';
import CheckIn from 'assets/images/support/TapToCheckIn14Sept2020.gif';
import ShowIn from 'assets/images/support/TapToShowIn14Sept2020.gif';


export const SUPPORT_ITEMS_FIRST_COLUMN = [
  {
    title: 'About data',
    questions: [
      {
        question: 'Do you record or access any of our personal data?',
        answer: 'No. We do not store any attributable or personal data, except where required to do so for COVID Contact Trace. Data held for this purpose is done so in compliance with GDPR and is deleted automatically after 21 days.',
      },
      {
        question: 'Do you rent or sell any data?',
        answer: 'No. We do not.',
      },
    ],
  },
  {
    title: 'About the F&B App',
    questions: [
      {
        question: 'Is the F&B app free?',
        answer: 'Yes. It will always be free to download and use from the stores',
      },
      {
        question: 'Is the F&B app available for IOS and Android?',
        answer: 'Yes. It is available in both app stores. See the links at the bottom of this page',
      },
      {
        question: 'How often does the app update with my content?',
        answer: 'Some of the content may be cached, other content will be refreshed when tapped to view or pulled to refresh.',
      },
    ],
  },
  {
    title: 'About Images',
    questions: [
      {
        question: 'What is the spec for images in Image Messages?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images may be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide. Content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for Home page images?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. Images may be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide. Content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for PDFs I want to attach to a message?',
        answer: 'You can attach PDFs to Messages that guests can download. Please ensure your PDFs are as small in size as possible - and no larger than 400k. Turn off editing functions and keep the quality suitable for mobile screens.',
      },
    ],
  },
  {
    title: 'Adding more than one Restaurant, Café or Bar',
    questions: [
      {
        question: 'I have added one, can I add more?',
        answer: `
        <p>Yes. Simply add a new destination or venue on My Home (you may need a subscription)</p>
        <img src="${AddSubs}" alt="Subscribe to our guest app" />
        `,
      },
      {
        question: 'Some pages are the same for all venues - do I have to add all the content again for each one?',
        answer: `
        <p>You can duplicate a destination or venue which will copy all your pages. You can then make individual changes as you need to.</p>
        <img src="${Duplicate}" alt="Duploicate Guest App Pages" />
        `,
      },
      {
        question: 'How many pages or tabs can I add to each venue?',
        answer: 'Our FREE version has some limits, but for upgraded accounts as many as you wish. We will automatically add a More tab to list any pages that dont fit on the navigation bar at the bottom of your app.',
      },
    ],
  },
  {
    title: 'Admin on mobile and tablets',
    questions: [
      {
        question: 'Does the Admin section work on mobiles and tablets?',
        answer: 'Yes. The whole Admin has been designed to work on any connected device, including Mobile and Tablets.',
      },
      {
        question: 'Is there a specific app I need to download to manage Admin?',
        answer: 'This is not necessary as we have optimised the site for use on Mobiles and Tablets. However, you can download and save the icon to your device Home Screen providing you with easy access right on your mobile!',
      },
      {
        question: 'How do I add Admin to my iPhone for easy access?',
        answer: 'Open Safari and go to FandB.app. Scroll up until you see a bar appear at the bottom of your screen with some icons in it. You should see in the middle a square box with an arrow pointing up. Tap this, and scroll down until you see an option to Add to Home Screen with a square +. Tap Add in the top right. You are done!',
      },
      {
        question: 'How do I add Admin to my Android phone for easy access?',
        answer: 'Open Chrome and go to FandB.app. Tap the 3 dots icon top right, and select Add to Home screen, tap Add. You are done!',
      },
    ],
  },
  {
    title: 'Telling guests about your app',
    questions: [
      {
        question: 'When will my app be available to guests?',
        answer: 'Once you have added your content to F&B, it is vaailable immediately.',
      },
      {
        question: 'What do guests need to see my app?',
        answer: 'They just need an Apple IOS or Android device',
      },
      {
        question: 'How can my guests get my app?',
        answer: `
        <p>Guests can get your app in several ways;</p>
        <p>1. They can download F&B app from the stores and search using the search terms you have added to your destination or venue.</p>
        <p>1. You can provide them with the QR code for each destination or venue, on your website or confirmation emails. You can add them to printed media (like posters at reception or labels in rooms). If they already have F&B on their devices your app automatically opens.</p>
      `,
      },
      {
        question: 'What happens they do not have the F&B app on their phones?',
        answer: 'Diners and Guests can just scan the QR code with their mobile phone camera. Our clever tech then automatically takes them to the correct app store, downloads F&B which then opens immediately on your own app!',
      },
    ],
  },
];

export const SUPPORT_ITEMS_SECOND_COLUMN = [
  {
    title: 'Paperless Menus',
    questions: [
      {
        question: 'Can I include images in the Menu?',
        answer: `
        <p>Yes. You can add Menu Sections and Menu Items, and include an image to head up each one or within the text field. These can be a pictire of the item, or a border or other menu separator.</p>
        <img src="${GuestAnimated}" alt="Edit Your Guest Information App" />
        `,
      },
      {
        question: 'Can I format the text in the Menus, like Bold and itallic etc?',
        answer: `
        <p>Yes. The menu builder includes a fully formatted text editor.</p>
        <img src="${ManualEditBold}" alt="Format Images in your Guest Information App" />
      `,
      },
    ],
  },
  {
    title: 'Adding Menus, choosing colors and fonts, images and Contact Trace / Check-in/out',
    questions: [
      {
        question: 'How many menus can we add?',
        answer: `
        <p>For the FREE version you can add 1 menu, upgrade and you can add as many menus as you like. Each menu also has sections, so you can choose to add all your F&B in one menu or separate for diners convenience.</p>
      `,
      },
      {
        question: 'How do I add a menu with our colors and menu items?',
        answer: `
        <p>Please watch this walkthru which shows how easy it is to add your F&B menu.</p>
        <img src="${EditMenu}" alt="Format Images in your Guest Information App" />
        `,
      },
      {
        question: 'How does your Contact Trace work?',
        answer: `
        <p>You can choose to add an optional Contact Trace tab.</p>
        <p>You can also additionally collect contact data, to download and provide to the authorities. Just check the box and we will collect the contact data and make it available to you for 21 days. GDPR compliant.</p>

        `,
      },
      {
        question: 'If I have to alert diners to potential infection or to find out more, how do I do this?',
        answer: `
        <p>There is more information about sending a Contact Trace message under 'About Messaging'</p>
        `,
      },
      {
        question: 'How do diners Check In?',
        answer: `
        <p>Once you have added the option in Admin, a new page is added to your app. Tapping this will enable guests to Check In.</p>
        <img src="${CheckIn}" alt="Format Images in your Guest Information App" />
        `,
      },
      {
        question: 'How do we make sure a diner has checked in?',
        answer: `
        <p>Servers can ask guests to show the green Checked In screen, showing date/time/party size. Guests can check out or we will do so automatically at the end of the day.</p>
        <img src="${ShowIn}" alt="Format Images in your Guest Information App" />
        `,
      },
    ],
  },
  {
    title: 'Configuring Your Brand',
    questions: [
      {
        question: 'How do I add my organisation name, logo, colours and typeface?',
        answer: `
        <p>You should be automatically taken to a page on Sign Up where you can add your business name, select your brand colours and typeface.</p>
        <p>You can update this anytime by clicking on Home and selecting the Configure Brand button below the app preview.</p>
        <p>The colors chosen in Configure Branding are reflected in the app Preview. You can also choose to add custom colors to the Rich Text Format (RTF) Text Editor.</p>
        <p>Your logo can be included in your Home screen image. For upgraded acounts, it can be added to every page by adding it on Configure Your Brand.</p>
        <img src="${ConfigureBrand}" alt="Configure your brand logo and colors" />
      `,
      },
      {
        question: 'Once I have Configured Brand - how do I add my Destination/Venue?',
        answer: `
        <p>Once you have Configured brand, you should be asked to add a Destination or Venue.</p>
        <p>This is the name of your Restaurant, Café or Bar.</p>
        <p>You can add 1 x for FREE. Additional venues within the same brand require a subscription.</p>
        <p>Go to My Home and add a subscription.</p>
        `,
      },
    ],
  },
  {
    title: 'About Messaging',
    questions: [
      {
        question: 'What is the difference between Messaging and a Quick Message?',
        answer: `
        <p>A quick message is just a Push notification, with no content associated with it.</p>
        <p>A Quick message will be sent to everyone with that venue as a favorite. It's really handy to communicate something quickly.</p>
        <p>Messaging has content associated with it, and appears in a tab within your app. An image, a headline, some text and optional PDF or YouTube video can be included. You can opt to send a Push notification.</p>
        `,
      },
      {
        question: 'Do I have to send a Push with a Message?',
        answer: 'You have the option to include a Push notfication if you wish - just check the box.',
      },
      {
        question: 'What images and branding are included in the Push message.',
        answer: 'Where allowed by the platform (IOS/Android) we include a logo thumbnail and your message image. For Enterprise Apps your own brand thumbnail is included.',
      },
      {
        question: 'How do I send an Image Message?',
        answer: `
        <p>Prepare your image as a .jpg (see 'About Images"), your headline and text. Go to the right hand menu and tap Messaging. This video shows how to send a message.</p>
        <img src="${AddMessage}" alt="Send image messages" />
      `,
      },
      {
        question: 'Sending a Quick Message',
        answer: `
        <p>This is just a quick push message. Go to the right hand menu and tap Quick Messaging. </p>
        <img src="${QuickMessage}" alt="Send quick messages" />
      `,
      },
      {
        question: 'Contact Trace Message',
        answer: `
        <p>You can send a message to any diner based on their check in date and time. You can include links to other pages in your app or to more information on the wider web.</p>
        <img src="${TrackTrace}" alt="Send quick messages" />
      `,
      },
      {
        question: 'How do I limit who receives a push message geographically',
        answer: `
        <p>You can set a 'Location' for your venue, and then limit Push notifcations to within a radius of that location. Individual device settings may affect delivery of these messages." />
      `,
      },
    ],
  },
  {
    title: 'About adding content to your app',
    questions: [
      {
        question: 'I have signed up, configured my brand. What next?',
        answer: 'You need to add a Destination or Venue Name - for example, you might be a collection under 1 brand, but each venue may have its own name.',
      },
      {
        question: 'How many Pages or Tabs can I add to each Destination or Venue?',
        answer: 'The FREE version has some limits (1 x Menu, 1 x RTF page, limited weekly messages). For upgraded accounts, as many as you wish. Our system will automatically add a More page to list any more than your 5th page.',
      },
      {
        question: 'What types of pages can I add?',
        answer: `
        <p>There are a number of different page types you can add to each Destination or Venue.</p>
        <p><strong>Home</strong> - every app must have a Home page (default). Include a logo on this if using the FREE version. Add one or more images as a gallery (just add as many images as you wish).</p>
        <p><strong>Menu</strong> - create your F&B menus, colors and images, choosing currency, menu items</p>
        <p><strong>Messages</strong> - (default) Contains your image messages.</p>
        <p><strong>Call</strong> - calls the number you right out of your app.</p>
        <p><strong>Contact Trace</strong> - add a Check In / Out screen and optional Contact Trace data collection (GDPR compiant).</p>
        <p><strong>Rich Text Format</strong> - adds a dynamic page, with any content you wish (text and images).</p>
        <p><strong>URL website address</strong> - links direct to any page on any website - must be https secure.</p>
        <p><strong>Facebook</strong> - login to Facebook in your browser and then add your ID in Admin, we'll automatically add the feed.</p>
        <p>Contacts directory - add a title, email and phone for any number of contacts (Reception, New Bookings, Housekeeping etc)</p>
        <img src="${PageTypes}" alt="Page types added to no code app" />
        `,
      },
      {
        question: 'How do I add an image to my Destination/Venue home page?',
        answer: `
        <p>Prepare an image and save as a .jpg (see 'About Images' section) and follow this animation to add an image to Home page.</p>
        <img src="${HomePageImage}" alt="Adding images to your Home Page guest app" />
      `,
      },
      {
        question: 'How do I add a phone number so guests can call right out of the app?',
        answer: `
        <p>Follow this animation, ensuring you use the international dialing code for your venue.</p>
        <img src="${AddPhoneNumber}" alt="Call right out of the hotel guest app" />
      `,
      },
      {
        question: 'I want to add a stand alone page to the app, with text and images?',
        answer: `
        <p>It's a good idea to write your text first and save any images. You can format the text as you would expect within the editor.</p>
        <img src="${AddRichText}" alt="Adding a rich text page view to your guest app" />
      `,
      },
      {
        question: 'I want to add a page that already exists on the web.',
        answer: `
        <p>As long as the website is hosted securely, and the link you add is 'https' - you can add any page you like. You can switch off the logo that appears too, ensuring branding is not duplicated.</p>
        <img src="${AddWebsite}" alt="Add any secure website" />
      `,
      },
    ],
  },

  {
    title: 'Enterprise Apps',
    questions: [
      {
        question: 'How do I get my own branded app in the app stores?',
        answer: `
        <p>Once you have set up your brand destinations and subscribed, just let us know via chat or email you'd like to upgrade to an Enterprise App - there are a few things you will need.</p>
        <p>Please read through the details here.</p>
      `,
      },
      {
        question: 'Your own App and Play Store accounts.',
        answer: `
        <p>You will need to set up developer accounts for both the App Store and the Play Store. They will ask for a small subscription payment. They require you to sign for their TCs, so only an official company representative can do this.</p>
        <p>This ensures you own the apps, they are in your own legal accounts in the stores.</p>
        <p>Apple will require an Apple ID and a Developer account.</p>
        <p>Once these are set up and approved, add us as Developers so we can publish your apps in your own accounts. We will show you how to do this.</p>
        `,
      },
      {
        question: 'You will need to send us some images.',
        answer: `
        <p>We will need an app icon (1024 x 1024 pixels) and a splash image (2208 x 2208 pixels), plus some app store images.</p>
        <p>We will send you a Photoshop template for your store images, but if you require any help creating these or other images please just ask us! We are here to help.</p>
        `,
      },
      {
        question: 'It can take a week or two.',
        answer: `
        <p>There are a few hoops to leap through setting up your accounts and getting your store listings sorted</p>
        <p>The whole process should not take more than a couple of weeks, possibly less.</p>
        <p>If you already have your own accounts in the app stores - if you have existing apps for example - there is no wait time!</p>
        `,
      },
    ],
  },
  {
    title: 'Hey, I need to ask something else!',
    questions: [
      {
        question: 'Questions not answered?',
        answer: `
        <p>We're here 24/7 - tap/click the red chat icon below and ask away!</p>
      `,
      },
      {
        question: 'I have a more complicated requirement, how can I contact you?',
        answer: `
        <p>Flip to our home page, and go to the form at the bottom. We'll come back to you asap!</p>
      `,
      },
    ],
  },

];
