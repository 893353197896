import React from 'react';
import { Helmet } from 'react-helmet';


export default {
  default: (
    <Helmet
      defaultTitle="Food and Beverage Digital Menu Upsell App"
    >
      <meta name="keywords" content="food, beverage, menu, experience, touchless, upsell, direct, booking, training, messaging, voucher, loyalty" />
      <meta name="description" content="Create a digital app menu and upselling app. Provide digital menus and communicate with patrons post visit in an uncluttered channel to upsell, re book, vouchers etc with in app messaging." />
    </Helmet>
  ),
  home: (
    <Helmet>
      <title>Digital Menu Upsell QR Code</title>
      <meta name="description" content="Digital Menu app for restaurants, cafes, bars who want to maximise upsell opportunity" />
    </Helmet>
  ),
  signUp: (
    <Helmet>
      <title>SignUp Digital Menu Upsell QR Code</title>
      <meta name="description" content="Sign up for basic free digital menu and upsell app, upgrade for full features" />
    </Helmet>
  ),
  logIn: (
    <Helmet>
      <title>Login to CMS / admin</title>
      <meta name="description" content="Login to manage content in your digital menu upselling app" />
    </Helmet>
  ),
  forgotPassword: (
    <Helmet>
      <title>Oooops I forgot my password</title>
      <meta name="description" content="Recover your password to edit digital menu" />
    </Helmet>
  ),
  aboutUs: (
    <Helmet>
      <title>About FandB</title>
      <meta name="description" content="About us, how we did this and more" />
    </Helmet>
  ),
  support: (
    <Helmet>
      <title>Legendary Support 24/7</title>
      <meta name="description" content="Legendary Support 24/7" />
    </Helmet>
  ),
  privacy: (
    <Helmet>
      <title>Privacy Information</title>
      <meta name="description" content="Privacy Info" />
    </Helmet>
  ),
  terms: (
    <Helmet>
      <title>Terms and Conditions</title>
      <meta name="description" content="Our Service Terms" />
    </Helmet>
  ),
  gdpr: (
    <Helmet>
      <title>GDPR</title>
      <meta name="description" content="Your data and our responsibility" />
    </Helmet>
  ),
};
