import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    maxWidth: '350px',
  },
  deviceContainer: {
    maxWidth: '100%',
  },
  appPreviewContentContainer: ({ typeface, fontColor }) => ({
    flex: '1',
    maxHeight: '100%',
    overflow: 'auto',
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
    backgroundColor: 'transparent',
  }),
  previewContainer: {
    position: 'absolute',
    top: '9.9%',
    left: '4%',
    width: '92%',
    height: '78.5%',
    backgroundColor: theme.palette.common.white,
  },
  appContainer: ({ backgroundColor: color }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: parseRgbaColor(color),
  }),
  topBar: ({ topBarColor: color }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1 / 3, 1),
    backgroundColor: parseRgbaColor(color),
    color: theme.palette.common.white,
  }),
  topBarText: ({ typeface }) => ({
    maxWidth: '70%',
    fontFamily: typeface,
  }),
  whiteBgContainer: {
    backgroundColor: theme.palette.common.white,
  },
  logoBar: ({ logoBarColor: color }) => ({
    height: '50px',
    textAlign: 'center',
    backgroundColor: parseRgbaColor(color),
  }),
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));
