import ImagePageEditor from '@pro/web-common/components/image-page-editor';
import UrlPageEditor from '@pro/web-common/components/url-page-editor';
import PhoneNumberPageEditor from '@pro/web-common/components/phone-number-page-editor';
import RtfPageEditor from '@pro/web-common/components/rtf-page-editor';
import DirectoryPageEditor from '@pro/web-common/components/directory-page-editor';
import FacebookPageEditor from '@pro/web-common/components/facebook-page-editor';
import MenuPageEditor from '@pro/web-common/components/menu-page-editor';
import CheckInPageEditor from '@pro/web-common/components/check-in-page-editor';
// import TimetablePageEditor from '@pro/web-common/components/timetable-page-editor';

import { getPageProps } from '@pro/web-common/utils/product';


const DefaultHomeImage = 'https://firebasestorage.googleapis.com/v0/b/foodbeverage-b0d50.appspot.com/o/assets%2FDefaultHome_183568705_small.jpg?alt=media&token=e86edd75-420d-4b16-aaf7-697d81d85a5c';
export const PAGE_TYPE = {
  IMAGE: {
    id: 'image',
    editor: ImagePageEditor,
    label: 'Image',
    limit: 1,
    infoKey: 'imageSection',
    noContentText: 'You have not yet added any content to the Home Page, please use the edit icon next to \'Home\' to add images',
  },
  MESSAGES: {
    id: 'messages',
    editor: null,
    label: 'Messages',
    limit: 1,
    infoKey: 'messagesSection',
    noContentText: null,
  },
  PHONE_NUMBER: {
    id: 'phoneNumber',
    editor: PhoneNumberPageEditor,
    label: 'Phone Number',
    limit: 1,
    infoKey: 'phoneNumberSection',
    noContentText: null,
  },
  URL: {
    id: 'url',
    editor: UrlPageEditor,
    label: 'Website (https)',
    infoKey: 'urlSection',
    noContentText: null,
  },
  RTF: {
    id: 'rtf',
    editor: RtfPageEditor,
    label: 'Rich Text Format',
    freeLimit: 1,
    infoKey: 'rtfSection',
    noContentText: null,
  },
  DIRECTORY: {
    id: 'directory',
    editor: DirectoryPageEditor,
    label: 'Contact Directory',
    limit: 1,
    infoKey: 'directorySection',
    noContentText: null,
  },
  FACEBOOK: {
    id: 'facebook',
    editor: FacebookPageEditor,
    label: 'Facebook',
    limit: 1,
    infoKey: 'facebookSection',
    noContentText: null,
  },
  MENU: {
    id: 'menu',
    editor: MenuPageEditor,
    label: 'Menu',
    limit: 4,
    freeLimit: 1,
    infoKey: 'menuSection',
    noContentText: null,
  },
  CHECK_IN: {
    id: 'checkIn',
    editor: CheckInPageEditor,
    label: 'Check In/Out',
    limit: 1,
    infoKey: 'checkInSection',
    noContentText: null,
  },
  // TIMETABLE: {
  //   id: 'timetable',
  //   editor: TimetablePageEditor,
  //   label: 'Timetable',
  //   infoKey: 'timetableSection',
  //   noContentText: null,
  // },
};

export const DEFAULT_APP_ICONS = {
  HOME: 'Home',
  MENU: 'LocalBar',
  MESSAGES: 'Message',
  WEBSITE: 'Language',
  CHECK_IN: 'PeopleAlt',
};


const HOME_PAGE = {
  ...getPageProps({
    iconId: DEFAULT_APP_ICONS.HOME,
    pageTypeId: PAGE_TYPE.IMAGE.id,
    label: 'Home',
  }),
  content: { files: [{ filePath: DefaultHomeImage }] },
  isDeletable: false,
};

const MENU_PAGE = {
  ...getPageProps({
    iconId: DEFAULT_APP_ICONS.MENU,
    pageTypeId: PAGE_TYPE.MENU.id,
    label: 'Menu',
  }),
};

const MESSAGES_PAGE = {
  ...getPageProps({
    iconId: DEFAULT_APP_ICONS.MESSAGES,
    pageTypeId: PAGE_TYPE.MESSAGES.id,
    label: 'Messages',
  }),
  isContentEditable: false,
  isDeletable: false,
};

const WEBSITE_PAGE = {
  ...getPageProps({
    iconId: DEFAULT_APP_ICONS.WEBSITE,
    pageTypeId: PAGE_TYPE.URL.id,
    label: 'Website',
  }),
  isDeletable: false,
};

const CHECK_IN_PAGE = {
  ...getPageProps({
    iconId: DEFAULT_APP_ICONS.CHECK_IN,
    pageTypeId: PAGE_TYPE.CHECK_IN.id,
    label: 'Check In/Out',
  }),
};

export const DEFAULT_PAGES = [
  HOME_PAGE,
  MENU_PAGE,
  MESSAGES_PAGE,
  WEBSITE_PAGE,
  CHECK_IN_PAGE,
];

export const MIN_PAGES_PER_PRODUCT = 4;
export const isTestVersion = true;
export const withLocationSetup = true;
