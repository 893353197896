import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import {
  Search as SearchIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';

import iphoneDevice from 'assets/images/iphone6-device.png';

import AppPreviewContent from '@pro/web-common/components/app-preview-content';
import AppPreviewNavigation from '@pro/web-common/components/app-preview-navigation';
import { colorPropsSchema } from '@pro/web-common/utils/style';

import { DEFAULT_PAGES, MORE_PAGE } from './config';
import { styles } from './styles';


const AppPreview = React.memo(({ brand, pages, productName, withLogoPreview, isInteractive, withNavigation, ...rest }) => {
  if (!brand) {
    return null;
  }

  const visiblePages = useMemo(() => pages.filter(({ isVisible, parentPageId }) => !parentPageId && (isVisible === undefined || isVisible)), [pages]);

  const defaultSelectedPageId = (visiblePages[0] || {}).id;
  const [selectedPageId, setSelectedPageId] = useState(defaultSelectedPageId);
  const {
    logo,
    logoImage,
    topBarColor,
    logoBarColor,
    backgroundColor,
    bottomBarColor,
    iconColor,
    iconActiveColor,
    fontColor,
    typeface,
    isDynamic,
  } = brand;

  const { url: logoUrl } = logo || {};

  const logoSource = logoImage || logoUrl;

  const classes = styles({
    backgroundColor,
    topBarColor,
    logoBarColor,
    fontColor,
    typeface,
  });

  const { showLogo, content, pageTypeId } = useMemo(() => visiblePages.find(({ id }) => id === selectedPageId) || {}, [selectedPageId, pages]);

  useEffect(() => {
    const selectedPageIsDeleted = visiblePages.findIndex(({ id }) => id === selectedPageId) === -1;

    if (selectedPageIsDeleted) {
      setSelectedPageId(defaultSelectedPageId);
    }
  }, [visiblePages, defaultSelectedPageId, selectedPageId]);

  return (
    <Box className={classes.container}>
      <img
        className={classes.deviceContainer}
        src={iphoneDevice}
        alt="iphone device"
      />

      <Box className={classes.previewContainer}>
        <Box className={classes.appContainer}>
          <Box>
            <Box className={classes.whiteBgContainer}>
              <Box className={classes.topBar}>
                <SearchIcon fontSize="small" />
                <Typography
                  variant="caption"
                  noWrap
                  className={classes.topBarText}
                >
                  {productName}
                </Typography>
                <FavoriteBorderIcon fontSize="small" />
              </Box>

              {(showLogo || withLogoPreview) && logoSource && (
                <Box className={classes.logoBar}>
                  <img
                    className={classes.logoImage}
                    src={logoSource}
                    alt="app logo"
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box className={classes.appPreviewContentContainer}>
            {pageTypeId && (
              <AppPreviewContent
                pageTypeId={pageTypeId}
                content={content}
                isDynamic={isDynamic}
                productName={productName}
                {...rest}
              />
            )}
          </Box>

          {
            withNavigation && (
              <Box className={classes.whiteBgContainer}>
                <AppPreviewNavigation
                  backgroundColor={bottomBarColor}
                  iconColor={iconColor}
                  iconActiveColor={iconActiveColor}
                  typeface={typeface}
                  pages={visiblePages}
                  isInteractive={isInteractive}
                  onPageSelect={(page) => setSelectedPageId(page.id)}
                  selectedPageId={selectedPageId}
                  morePage={MORE_PAGE}
                />
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  );
});


AppPreview.propTypes = {
  brand: PropTypes.shape({
    logoImage: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
    }),
    topBarColor: colorPropsSchema.isRequired,
    logoBarColor: colorPropsSchema.isRequired,
    backgroundColor: colorPropsSchema.isRequired,
    bottomBarColor: colorPropsSchema.isRequired,
    iconColor: colorPropsSchema.isRequired,
    iconActiveColor: colorPropsSchema.isRequired,
    fontColor: colorPropsSchema.isRequired,
    typeface: PropTypes.string.isRequired,
    isDynamic: PropTypes.bool,
  }).isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.string,
  })),
  productName: PropTypes.string,
  withLogoPreview: PropTypes.bool,
  isInteractive: PropTypes.bool,
  withNavigation: PropTypes.bool,
};

AppPreview.defaultProps = {
  pages: DEFAULT_PAGES,
  withLogoPreview: false,
  isInteractive: false,
  productName: '',
  withNavigation: true,
};


export default AppPreview;
