import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: ({ backgroundColor }) => ({
    backgroundColor: backgroundColor ? parseRgbaColor(backgroundColor) : 'none',
    paddingBottom: theme.spacing(1),
  }),
  title: ({ typeface }) => ({
    margin: theme.spacing(1 / 2, 1),
    fontFamily: typeface,
    fontWeight: 'bold',
    fontSize: '20px',
  }),
  html: {
    margin: theme.spacing(1 / 2, 1),
  },
  imageContainer: {
    display: 'block',
    marginBottom: theme.spacing(1.5),
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
