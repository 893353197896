import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';


export const fileFormats = [
  '.doc',
  '.docx',
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/*',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/x-png',
];

export const formValidationSchema = () => Yup.object().shape({
  name: Yup.string()
    .required(),
  email: Yup.string()
    .email()
    .required(),
  website: Yup.string()
    .matches(REGEXP.URL)
    .required(),
  file: Yup.mixed()
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value ? fileFormats.includes(value.type) : true,
    ).required(),
});
