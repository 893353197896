import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
} from '@material-ui/core';

import Promo1 from 'assets/images/promo/designerHelp.png';

import PromoItem from './promo-item';


const PromoSection = React.memo(() => (
  <Box
    bgcolor="primary.light"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Help when you need it.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Building your own F&amp;B Digital Menu app is now easier than ever, but we understand resources are spread thin right now. We&apos;re here to help.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PromoItem
            description={(
              <>
                <p>We&apos;ll work with you to set up your complete Digital Menu app and design your initial Upsell Strategy.</p>
                <p>We&apos;ll show you how to optimise your menu architecture &amp; messaging to deliver maximium upsell opportunity.</p>
                <p><strong>The complete Digital Menu &amp; Upsell Package</strong></p>
                <li>Set up your menu app to match your brand &amp; design</li>
                <li>Add menus + optional table booking &amp; online ordering</li>
                <li>Design / plan your initial Upsell Strategy</li>
                <li>Provide full training to key staff</li>
                <li>Provide QR artworks &amp; short links</li>
                <li>3 months full subscription included</li>
                <p>We just need is your PDF menu, website address and contact details.</p>
                <p>This package has a 1 off cost of £399.00 plus VAT</p>
              </>
            )}
          />

          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to="/offer"
          >
            SET ME UP!
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PromoItem
            image={Promo1}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


PromoSection.propTypes = {};

PromoSection.defaultProps = {};


export default PromoSection;
