export const UPGRADED_PLAN_FEATURES = [
  {
    id: '1',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited Users to your Admin - access by Restaurant/Bar or access all',
  },
  {
    id: '2',
    label: 'Multi Language Guest Information',
    description: 'Automatically translate your Guest Information into multiple langauges',
  },
  {
    id: '3',
    label: 'Enhanced Messaging With Image and Push Notifications',
    description: 'Use advanced features to send messages with images, video and PDFs',
  },
  {
    id: '4',
    label: 'Search Terms/Tags',
    description: 'List search terms Guests can use to see your Hotel, Resort, Attraction or Tour app',
  },
];

export const PLAN_TYPE_FREE_ID = 'free';
export const PLAN_TYPE_SINGLE_ID = 'single';
export const PLAN_TYPE_MULTI_TEN_ID = 'multiTen';
export const PLAN_TYPE_BANK_TRANSFER = 'bankTransfer';

export const ACTIVE_SUBSCRIPTION_LIMIT = null;

export const PLAN_TYPE_PRICES = {
  [PLAN_TYPE_SINGLE_ID]: {
    EUR: {
      price: '32.00',
    },
    GBP: {
      price: '29.00',
    },
    USD: {
      price: '35.00',
    },
  },
  [PLAN_TYPE_MULTI_TEN_ID]: {
    EUR: {
      price: '260.00',
    },
    GBP: {
      price: '190.00',
    },
    USD: {
      price: '280.00',
    },
  },
};

export const PLAN_TYPES = [
  {
    id: PLAN_TYPE_FREE_ID,
    title: 'Free Forever',
    canAdd: false,
    features: [
      {
        id: 'products_count',
        label: '1 x Restaurant/Café/Bar',
      },
      {
        id: 'users_count',
        label: '1 x Admin User',
      },
      {
        id: 'messaging',
        label: 'Messaging (Limited to 1 per week)',
      },
      {
        id: 'menu',
        label: 'Menu (Limited to 1 Menu)',
      },
      {
        id: 'check-in',
        label: 'Check In/Contact Trace',
      },
      {
        id: 'contacttrace',
        label: 'Data Collection (GDPR Compliant)',
      },
    ],
  },
  {
    id: PLAN_TYPE_SINGLE_ID,
    title: '1 Restaurant/Bar',
    availableProducts: 1,
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Upgrade x 1 @${currencySign}${price} per month`,
    features: [
      {
        id: 'products_count',
        label: '1 x Restaurant/Café/Bar',
      },
      {
        id: 'users_count',
        label: 'Unlimited Admin Users',
      },
      {
        id: 'messaging',
        label: 'Messaging (Unlimited)',
      },
      {
        id: 'menu',
        label: 'Menu (Unlimited)',
      },
      {
        id: 'check-in',
        label: 'Check In/Contact Trace',
      },
      {
        id: 'contacttrace',
        label: 'Data Collection (GDPR Compliant)',
      },
      {
        id: 'search',
        label: 'Search Terms/Tags',
      },
      {
        id: 'branding',
        label: 'Add logo throughout',
      },
    ],
  },
  {
    id: PLAN_TYPE_MULTI_TEN_ID,
    title: '10 Restaurants / Cafés / Bars Pack',
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Upgrade x 10 @${currencySign}${price} per month`,
    availableProducts: 10,
    features: [
      {
        id: 'products_count',
        label: '10 x Restaurants / Cafés / Bars',
      },
      {
        id: 'users_count',
        label: 'Unlimited Admin Users',
      },
      {
        id: 'messaging',
        label: 'Messaging (Unlimited)',
      },
      {
        id: 'menu',
        label: 'Menu (Unlimited)',
      },
      {
        id: 'check-in',
        label: 'Check In/Contact Trace',
      },
      {
        id: 'contacttrace',
        label: 'Data Collection (GDPR Compliant)',
      },
      {
        id: 'search',
        label: 'Search Terms/Tags',
      },
      {
        id: 'branding',
        label: 'Add logo throughout',
      },
    ],
  },
  {
    id: PLAN_TYPE_BANK_TRANSFER,
    title: 'Bank Transfer',
    canAdd: false,
  },
];

export const UPGRADE_PLAN_FEATURES = [
  {
    id: 'users',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited Users to your admin - access by Restaurant/Café/Bar or access all',
  },
  {
    id: 'menu',
    label: 'Add Multiple Menus',
    description: 'Add as many Menus as you like.',
  },
  {
    id: 'messaging',
    label: 'Unlimited Messaging With Image and Push Notifications',
    description: 'Use advanced and unlimited features to send push messages with images, video and PDFs',
  },
  {
    id: 'search_tags',
    label: 'Search Terms/Tags',
    description: 'List search terms Guests and Diners can use to find your Restaurant/Café/Bar',
  },
  {
    id: 'branding',
    label: 'Add logo throughout',
    description: 'Add your logo to all pages / views',
  },
];

export const OFFER_TYPE_PRICES = {
  EUR: {
    price: '470.00',
  },
  GBP: {
    price: '399.00',
  },
  USD: {
    price: '480.00',
  },
};
