import AppStoreIcon from 'assets/images/store-icons/appStore.png';
import PlayStoreIcon from 'assets/images/store-icons/playStore.png';


// TODO: update this links
export const STORE_LINKS = [
  {
    id: 'appStore',
    href: 'https://apps.apple.com/us/app/foodbeverage/id1535439451',
    icon: AppStoreIcon,
  },
  {
    id: 'playStore',
    href: 'https://play.google.com/store/apps/details?id=com.kappow.foodbeverage',
    icon: PlayStoreIcon,
  },
];

export const dynamicLink = 'https://fandb.page.link';
export const websiteDomain = 'https://fandb.app';
export const appStoreId = '1535439451';
export const iosBundleId = 'com.appsprout.foodbeverage';
export const apn = 'com.kappow.foodbeverage';
